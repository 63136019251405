import React, {useEffect} from "react";
import {getContributorEntitlementCounts, removeContributors } from "../../utils/interact";
import {Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setContributors,setEntitlements} from '../../store/contributorsState'
import {Link} from "react-router-dom";
import {setContributorsToRemove, setWalletAddress} from "../../store/selectedContributer";

export default function ContributorAdmin(){


    const { contributors, entitlements } = useSelector((state) => state.contributors);
    const { walletAddress, contributorsToRemove } = useSelector((state) => state.selectedContributor);
    const dispatch = useDispatch();

    useEffect(async () => {
        await getContributorEntitlementCounts().then(result => {
            dispatch(setContributors(result[0]))
            dispatch(setEntitlements(result[1]))
        })
    },[])

    function handleCheckboxChange(item){
        let arr = [...contributorsToRemove];
        console.log(arr)
        if(arr.includes(item)){
            console.log("includes")
            arr.splice(arr.indexOf(item), 1);
        } else {
            arr.push(item);
        }
        console.log(arr)
        dispatch(setContributorsToRemove(arr))
    }

    return(
        <div className="ListingsTableContainer_listingsTableContainer__h1r2j ">
                <div className="ui container">
                    <div className="dcl tabs">
                        <div className="dcl tabs-left">
                            <h3>Add/Remove Contributors</h3>
                        </div>
                        <div className="dcl tabs-right">
                            <button
                                className="ui small primary button"
                            ><Link to="/contributors/add">Add New</Link></button>
                        </div>
                    </div>
                </div>
            <div className="tableContainer">
                <div className="TableContent">
                    <table className="ui very basic table">

                        <tbody>
                        <tr>
                            <th></th>
                            <th>Contributor Address <span style={{color: '#ff2d55'}}> click to edit</span></th>
                            <th>Entitlements</th>
                        </tr>

                            {
                                contributors.map((item, index) => {
                                    return (
                                        <tr key={`Contributor_${index}`}>
                                            <td>
                                                <input type="checkbox"
                                                       onChange={() => handleCheckboxChange(item)}/>
                                            </td>
                                            <td>
                                                <Link to="/edit" onClick={() => {
                                                    console.log(item)
                                                   dispatch(setWalletAddress(item))
                                                }}>{item}</Link>
                                            </td>
                                            <td>
                                                {entitlements[index]}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Button
                className="ui small primary button"
                onClick={() => {
                    removeContributors(contributorsToRemove).then(result => {
                        console.log(result)
                    })
                }}
            >
                Remove
            </Button>

        </div>
    )
}