import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {TextArea} from "decentraland-ui";
import { addEntitlements } from "../../utils/interact";


export default function AddEntitlements(){

    const { entitlements } = useSelector((state) => state.entitlements);
    const { walletAddress } = useSelector((state) => state.selectedContributor);
    const dispatch = useDispatch();
    const [wallets, setWallets] = useState("");

    function handleSplit(){
        let arr = wallets.split(",");
        arr = arr.map(item => item.trim().replace(/\n/g, ''));
        return arr;
    }

    return(
        <div className="ui container">
            <div className="ListingsTableContainer_listingsTableContainer__h1r2j ">
                <h2>Add Entitlements</h2>
                <p>Contributor: {walletAddress}</p>
                <div className="tableContainer">
                   <TextArea
                       value={entitlements}
                       onChange={(e) => {
                       setWallets(e.target.value)
                   }}/>
                </div>
                <Button
                    className="ui small primary button"
                    onClick={() => {
                        let arr = handleSplit()
                        addEntitlements(walletAddress,arr).then(result => {
                            console.log(result)
                        })
                    }}
                >
                    Add Entitlements
                </Button>
            </div>
        </div>
    )
}