import React, {useEffect, useState} from "react";
import { useLocation, Switch, useHistory} from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import {FAQ} from "./components/sections/FAQ";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import LayoutLogIn from "./layouts/LayoutLogIn";

// Views
import Contributions from "./views/Contributions";
import LogIn from "./views/LogIn";

// AuthCheck Utils
import {checkConnectedWalletAddress, userCheck} from "./utils/AuthCheck";
import {Test} from "./components/sections/Test";
import Entitlements from "./components/sections/Entitlements";
import AddEntitlements from "./components/sections/AddEntitlements";
import RemoveEntitlements from "./components/sections/RemoveEntitlements";
import AddContributors from "./components/sections/AddContributers";
import AppRouteAdmin from "./utils/AppRouteAdmin";
import {setIsPrivate} from "./store/adminUser";
import {useDispatch, useSelector} from "react-redux";

const App = () => {
  const history = useHistory();
  let location = useLocation();
  const {isPrivate} = useSelector((state) => state.adminUser)
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.classList.add("is-loaded");
  }, [location]);

  useEffect(() => {
    const auth = checkConnectedWalletAddress();
    if (auth.auth) {
      history.push("/contributors");
    } else {
      history.push("/login");
    }
  }, []);

  useEffect(async () => {
      await userCheck().then(result => {
            dispatch(setIsPrivate(!result.admin));
      })
  })
  return (
      <Switch>
        {/* route to contributors */}
          <AppRoute
              exact
              path="/contributors"
              component={Entitlements}
              layout={LayoutDefault}
          />
        {/* route for login */}
        <AppRoute
            exact
            path="/login"
            component={LogIn}
            layout={LayoutLogIn}
        />

        {/* private Routes */}

          <AppRouteAdmin
              exact
              path="/contributoradmin"
              isPrivate={isPrivate}
              component={Contributions}
              layout={LayoutDefault}
          />

          <AppRouteAdmin
              exact
              path="/contributors/add"
              component={AddContributors}
              isPrivate={isPrivate}
              layout={LayoutDefault}
          />
          <AppRouteAdmin
              exact
              path="/add"
              component={AddEntitlements}
              isPrivate={isPrivate}
              layout={LayoutDefault}
          />
          <AppRouteAdmin
              exact
              path="/edit"
              component={RemoveEntitlements}
              isPrivate={isPrivate}
              layout={LayoutDefault}
          />
          <AppRoute
              exact
              path="/FAQ"
              component={FAQ}
              layout={LayoutDefault}
          />
      </Switch>
  );
};

export default App;

