import { createSlice} from "@reduxjs/toolkit";

export const operatorSlice = createSlice({
    name: "selectedContributor",
    initialState: {
        operatorWallet: "",
        sceneIdsForOperator: [],
        estateId: ""
    },
    reducers: {
        setOperatorWallet: (state, action)=> {
            state.operatorWallet = action.payload
        },
        setSceneIdsForOperator: (state, action) => {
            state.sceneIdsForOperator = action.payload
        },
        setEstateId: (state, action) => {
            state.estateId = action.payload
        }
    }
});

export const { setOperatorWallet, setSceneIdsForOperator, setEstateId } = operatorSlice.actions;

export default operatorSlice.reducer