import React from "react";
// import sections
import ContributionsBlock from "../components/sections/ContributionsBlock";

const Contributions = () => {
  return (
        <ContributionsBlock topDivider />
  );
};

export default Contributions;
