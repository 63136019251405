import React, {useEffect} from "react";
import {Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setEntitlementsToRemove} from "../../store/selectedContributer";
import {Link} from "react-router-dom";
import {getEntitlementsByWalletAddress, removeEntitlements} from "../../utils/interact";
import {setCoordinates, setSceneIds} from "../../store/entitlementsState";

export default function RemoveEntitlements(){

    const { coordinates, sceneIds } = useSelector((state) => state.entitlements);
    const { walletAddress, entitlementsToRemove } = useSelector((state) => state.selectedContributor);
    const dispatch = useDispatch();

    useEffect( async () => {
        getEntitlementsByWalletAddress(walletAddress).then(result => {
            console.log(result);
            dispatch(setCoordinates(result[1]));
            dispatch(setSceneIds(result[0]));
        })
    },[])

    function handleCheckboxChange(item){
        let arr = [...entitlementsToRemove];
        console.log(arr)
        if(arr.includes(item)){
            console.log("includes")
            arr.splice(arr.indexOf(item), 1);
        } else {
            arr.push(item);
        }
        console.log(arr)
        dispatch(setEntitlementsToRemove(arr))
    }

    return(
        <div className="ui container">
            <div className="ListingsTableContainer_listingsTableContainer__h1r2j ">
                <div className="ui container">
                    <div className="dcl tabs">
                        <div className="dcl tabs-left">
                            <h3>Edit Contributor</h3>
                            <p>Contributor: {walletAddress}</p>
                        </div>
                        <div className="dcl tabs-right">
                            <button
                                className="ui small primary button"
                            ><Link to="/add">Add New Entitlements</Link></button>
                        </div>
                    </div>
                </div>
                <div className="tableContainer">
                    <div className="TableContent">
                        <table className="ui very basic table">

                            <tbody>
                            <tr>
                                <th></th>
                                <th>Land ID</th>
                                <th>Token ID</th>
                            </tr>

                            {
                                sceneIds.map((item, index) => {
                                    return (
                                        <tr key={`Contributor_${index}`}>
                                            <td>
                                                <input type="checkbox"
                                                       onChange={() => handleCheckboxChange(item)}/>
                                            </td>
                                            <td>
                                                {item}
                                            </td>
                                            <td>
                                                {coordinates[index]}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <Button
                    className="ui small primary button"
                    onClick={() => {
                        removeEntitlements(walletAddress,entitlementsToRemove).then(result => {
                            console.log(result)
                        })
                    }}
                >
                    Remove Entitlements
                </Button>

            </div>
        </div>
    )
}