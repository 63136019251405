import { configureStore,  } from "@reduxjs/toolkit";
import adminUserReducer from './adminUser';
import contributorReducer from './contributorsState';
import entitlementReducer from './entitlementsState';
import selectedContributorReducer from './selectedContributer';
import operatorStateReducer from "./operatorState";

// can use multiple stores for separate items
export default configureStore({
    reducer: {
        adminUser: adminUserReducer,
        contributors: contributorReducer,
        entitlements: entitlementReducer,
        selectedContributor: selectedContributorReducer,
        operator: operatorStateReducer
    },
});
