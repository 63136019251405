import React, {useEffect, useState} from "react";
import {Button, Modal, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import { getEntitlementsByWalletAddress,getCurrentWalletConnected,setManyLandUpdateOperator } from '../../utils/interact'
import { setCoordinates,setSceneIds, setEstate } from "../../store/entitlementsState";
import { setEstateId, setSceneIdsForOperator, setOperatorWallet} from "../../store/operatorState";
import {TextField} from "@mui/material";
import {setEntitlementsToRemove} from "../../store/selectedContributer";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Entitlements(){

    const { coordinates, sceneIds, estate } = useSelector((state) => state.entitlements);
    const { operatorWallet, estateId, sceneIdsForOperator } = useSelector((state) => state.operator);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();

    useEffect( async () => {
        const wallet = await getCurrentWalletConnected()
        getEntitlementsByWalletAddress(wallet.address).then(result => {
            console.log(result);
            dispatch(setCoordinates(result[1]));
            dispatch(setSceneIds(result[0]));
            dispatch(setEstate(result[2]));
        })
    },[])


    function handleCheckboxChange(item, id){
        let arr = [...sceneIdsForOperator];
        console.log(arr)
        if(arr.includes(item)){
            console.log("includes")
            arr.splice(arr.indexOf(item), 1);
        } else {
            const mismatchedCheckbox = arr.find((sceneId) => estate[sceneIds.indexOf(sceneId)] !== id);
            if (mismatchedCheckbox) {
                document.getElementById(item).checked = false
                alert(`You can't select this checkbox because it belongs to a different estate.`);
                return;
            }
            arr.push(item);
        }
        console.log(arr)
        dispatch(setSceneIdsForOperator(arr))
        dispatch(setEstateId(id))
    }

    return(
        <div className="ui container">
            <div className="ListingsTableContainer_listingsTableContainer__h1r2j ">
                <div className="tableContainer">
                    <div className="TableContent">
                        <table className="ui very basic table">

                            <tbody>
                            <tr>
                                <th></th>
                                <th>SceneId</th>
                                <th>Coordinates</th>
                                <th>Estate</th>
                            </tr>

                            {
                                sceneIds.map((item, index) => {
                                    return (
                                        <tr key={`Contributor_${index}`}>
                                            <td>
                                                <input type="checkbox"
                                                       id={item}
                                                       onChange={() => handleCheckboxChange(item,estate[index])}/>
                                            </td>
                                            <td>
                                                {item}
                                            </td>
                                            <td>
                                                {coordinates[index]}
                                            </td>
                                            <td>
                                                { estate[index] ? estate[index] : "" }
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <Button
                    className="ui small primary button"
                    onClick={() => handleOpen()}
                >
                    Set Operator
                </Button>
                {/*<Button*/}
                {/*    className="ui small primary button"*/}
                {/*>Claim</Button>*/}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="ui page modals dimmer transition visible active SignIn center" >
                    <div className="ui modal transition visible active " style={style}>
                        <Typography className="dcl modal-navigation" variant="h6" component="h2">
                            Set Operator Wallet
                        </Typography>
                        <div className="dcl modal-navigation-button modal-navigation-close" onClick={handleClose}>
                        </div>
                        <div style={{margin: '15px'}}>
                            <TextField
                                fullWidth={true}
                                label="Wallet Address"
                                value={operatorWallet}
                                onChange={(e) => {
                                    dispatch(setOperatorWallet(e.target.value))
                                }}
                            />
                        </div>
                        <div style={{margin: '15px'}}>
                            <Button
                                className="ui small primary button"
                                onClick={() => {
                                    console.log(operatorWallet)
                                    console.log(sceneIdsForOperator)
                                    console.log(estateId)
                                    setManyLandUpdateOperator(estateId, sceneIdsForOperator, operatorWallet).then(result =>{
                                        console.log(result)
                                    })
                                }}
                            >
                                set
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}