import { createSlice} from "@reduxjs/toolkit";

export const contributorsSlice = createSlice({
    name: "contributors",
    initialState: {
        contributors: [],
        entitlements: []
    },
    reducers: {
        setContributors: (state, action)=> {
            state.contributors = action.payload
        },
        setEntitlements: (state, action) =>{
            state.entitlements = action.payload
        }
    }
});

export const { setContributors,setEntitlements } = contributorsSlice.actions;

export default contributorsSlice.reducer;