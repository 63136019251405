import { createSlice} from "@reduxjs/toolkit";

export const selectedContributorSlice = createSlice({
    name: "selectedContributor",
    initialState: {
        walletAddress: "",
        entitlementsToRemove: [],
        contributorsToRemove: [],
        entitlementsToClaim: []
    },
    reducers: {
        setWalletAddress: (state, action)=> {
            state.walletAddress = action.payload
        },
        setEntitlementsToRemove: (state, action) => {
            state.entitlementsToRemove = action.payload
        },
        setContributorsToRemove : (state, action) => {
            state.contributorsToRemove = action.payload
        },
        setEntitlementsToClaim: (state, action) => {
            state.entitlementsToClaim = action.payload
        }
    }
});

export const { setWalletAddress, setEntitlementsToRemove, setContributorsToRemove, setEntitlementsToClaim } = selectedContributorSlice.actions;

export default selectedContributorSlice.reducer;