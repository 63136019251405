import React from 'react';
// import sections
import LogInBlock from '../components/sections/LogInBlock';

const Home = () => {

  return (
      <LogInBlock topDivider />
  );
}

export default Home;