import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {TextArea} from "decentraland-ui";
import {addContributors, removeContributors} from "../../utils/interact";

export default function AddContributors(){

    const { contributors } = useSelector((state) => state.contributors);
    const dispatch = useDispatch();
    const [wallets, setWallets] = useState("");

    useEffect(() => {

    },[contributors])

    function handleSplit(){
        let arr = wallets.split(",");
        arr = arr.map(item => item.trim().replace(/\n/g, ''));
        return arr;
    }


    return(
        <div className="ui container">
            <h3>Add Contributors</h3>
            <div className="ListingsTableContainer_listingsTableContainer__h1r2j ">
                <div className="tableContainer">
                    <TextArea
                        value={wallets}
                        onChange={(e) => {
                            setWallets(e.target.value)
                        }}/>
                </div>
                <Button
                    className="ui small primary button"
                    onClick={() => {
                        let arr = handleSplit()
                        addContributors(arr).then(result => {
                            console.log(result)
                        })
                    }}
                >
                    Add Contributors
                </Button>
            </div>
        </div>
    )
}