import { createSlice} from "@reduxjs/toolkit";

export const entitlementsSlice = createSlice({
    name: "entitlements",
    initialState: {
       coordinates: [],
        sceneIds: [],
        estate: []
    },
    reducers: {
        setCoordinates: (state, action)=> {
            state.coordinates = action.payload
        },
        setSceneIds: (state, action) => {
            state.sceneIds = action.payload
        },
        setEstate: (state, action) => {
            state.estate = action.payload
        }
    }
});

export const { setCoordinates,setSceneIds, setEstate } = entitlementsSlice.actions;

export default entitlementsSlice.reducer;